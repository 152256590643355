import NumberFormat from 'react-number-format';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import _ from 'lodash';

const Product = ({ smartShipCheck, minCartAutoshipCheck, Cart, deleteproduct, index, register, Add, Sub, updateqty, setcosttype, costtype, swithtoautoship, amountCalculations, setAmountCalculations, setcoupon }) => {
  //check product is selected to autoship or normal

  return (
    <>
      <div className="mini-cart" key={index} style={Cart?.extraProduct ? {
        background: "#fff",
        border: 'none',
      } : {}}>
        {Cart?.extraProduct &&
          <div className='free_product_title'>
            <p>Your Free Product!</p>
          </div>
        }
        <div className="min-cat-uppper">

          <div className="cart-image">
            {Cart?.product?.product_images[0]?.image
              ?
              <img src={`${process.env.API_URL}${Cart?.product.product_images[0]?.image}`} />
              :
              <img src="/images/no-image.jpg" />
            }
          </div>
          <div className="mini-cart-des">
            <div className="cart-product-details">
              {Cart?.product?.name}
            </div>
            {(!Cart?.extraProduct) ?
              <div className="main-qty-sec">
                <div className="box">
                  {Cart?.qty_display &&
                    <div id="qty">
                      <button type="button"
                        name={`${Cart?.product?.id},${null}`}
                        disabled={Cart?.quantity === "1"}
                        id={Cart?.product?.id}
                        className="sub"
                        value={+Cart?.quantity}
                        onClick={(e) => { Sub(e, Cart?.id) }}>-</button>
                      <input
                        name={`${Cart?.product?.id},${null}`}
                        type="text"
                        readOnly
                        ref={register &&
                          register({
                            valueAsNumber: true,
                          })
                        }
                        value={+Cart?.quantity}
                      />
                      <button type="button"
                        name={`${Cart?.product?.id},${null}`}
                        id={Cart?.product?.id} data-value={Cart?.product?.quantity}
                        className="add"
                        value={+Cart?.quantity}
                        onClick={(e) => { Add(e, Cart?.id) }}>+</button>
                    </div>
                  }
                </div>
              </div>
              :
              <div style={{
                width: "100%",
                maxWidth: "112px",
                textAlign: "center",
              }}>
                <input
                  name={`${Cart?.product?.id},${null}`}
                  type="text"
                  style={{
                    width: "40px",
                    background: "#fff",
                    border: "1px solid var(--blue)",
                    textAlign: "center",
                    padding: "7.5px"
                  }}
                  readOnly
                  ref={
                    register({ valueAsNumber: true })
                  }
                  value={+Cart?.quantity}
                />
              </div>
            }
            <div className="cart-product-details">
              <p className="title">
                {Cart?.product?.discount_combination !== true || Cart?.product?.discountAmount === null ?

                  <NumberFormat value={`${parseFloat(Cart?.product?.cost_price * Cart?.quantity).toFixed(2)}`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={value => <div>{
                      (minCartAutoshipCheck !== "True" && smartShipCheck !== true) ? value + " " + "USD" :
                        `$${parseFloat(Cart?.product?.[`${(minCartAutoshipCheck === "True" || smartShipCheck)
                          ? "autoship_cost_price" : "cost_price"}`] * Cart?.quantity).toFixed(2)} ` + " " + "USD"
                      // + (Cart?.is_autoship === "True" ? ' / $' + parseFloat(Cart?.product?.autoship_cost_price * Cart?.quantity).toFixed(2) + " " + "USD" : "") + " "
                      // + (Cart?.is_autoship === "True" ? 'SmartShip' : "")
                    }
                    </div>
                    } />

                  :

                  <NumberFormat value={`${parseFloat(Cart?.product?.cost_price * Cart?.quantity).toFixed(2)}`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={value => <div>{
                      // (Cart?.is_autoship !== 'True' && minCartAutoshipCheck !== "True" && )
                      (minCartAutoshipCheck !== "True" && smartShipCheck !== true) ? (<>
                        <div className="price-afterdiscount">{parseFloat(Cart?.product?.afterDiscoutAmount).toFixed(2) + " " + "USD"} </div>/
                        <div className="price-beforediscount">{parseFloat(Cart?.product?.beforeDiscoutAmount).toFixed(2) + " " + "USD"}</div>
                      </>) : (
                        <>
                          <div className="price-afterdiscount">
                            {`$${parseFloat(Cart?.product?.autoship_afterDiscoutAmount).toFixed(2)} USD/`}
                          </div>
                          <div className="price-beforediscount">
                            {Cart?.is_autoship === "True" ? (
                              `$${parseFloat(Cart?.product?.autoship_beforeDiscoutAmount).toFixed(2)} USD`
                            ) : (
                              `$${parseFloat(Cart?.product?.autoship_beforeDiscoutAmount).toFixed(2)} USD`
                            )}
                          </div>
                          {Cart?.is_autoship === "True" && (
                            <div className="smartship-label">SmartShip</div>
                          )}
                        </>

                      )}
                    </div>} />

                }
              </p>
            </div>
            {(!Cart?.extraProduct) &&
              <div className="cart-product-details">
                <div className="box">
                  {(Cart?.product?.product_type === "Product" || Cart?.product?.product_type === "Bogo") &&
                    <div className="select">
                      <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                        value={Cart?.is_autoship === 'True' ? "AutoShip" : "Normal"}
                        data-product-id={Cart?.product?.id}
                        data-product-qty={Cart?.quantity}
                        data-variant-id={null}
                        onChange={(e) => {
                          const found = costtype.data.some(el => el.id === Cart?.product?.id);
                          if (!found) {
                            setcosttype({ ...costtype, data: [...costtype.data, { "id": Cart?.product?.id, "cartId": Cart?.id, "value": e.target.value, "variant_id": null }] })

                          }
                          else {
                            const objIndex = costtype.data.findIndex((obj => obj.id == Cart?.product?.id));
                            costtype.data.splice(objIndex, 1);

                            setcosttype({ ...costtype, data: [...costtype.data, { "id": Cart?.product?.id, "cartId": Cart?.id, "value": e.target.value, "variant_id": null }] })
                          }
                          swithtoautoship(e, Cart?.id)
                          setAmountCalculations();
                          setcoupon()
                        }}
                      >
                        <option value="Normal" >One-time Only</option>
                        <option value="AutoShip">SmartShip</option>
                        {/* <option value="AutoShip">AutoShip</option> */}

                      </select>
                    </div>
                  }
                </div>
              </div>
            }

          </div>
        </div>

        <div className="cart-product-qty-del">
          {(!Cart?.extraProduct) &&
            <button><DeleteOutlineIcon id={Cart?.product?.id} data-value={Cart?.variant?.id} onClick={(e) => { deleteproduct(e, Cart?.id, Cart?.is_autoship) }} className="dlt" /></button>
          }
        </div>

      </div >
    </>)
}
export default Product;