import styled from "styled-components";

const MinCartStyle = styled.div`
  z-index: 9999;
  position: relative;
  && .mini-cart-sec {
    .cart-heading {
      position: relative;
      h1 {
        margin: 0;
      }
      button {
        position: absolute;
        left: 0px;
        top: 0px;
        background: transparent;
        border: 0;
        color: #fff;
        font-weight: bold;
        background: #fff;
        color: #06356a;
        width: 30px;
        height: 100%;
        font-size: 15px;
        border-radius: 0;
        padding: 0;
        }
    }
    .Cart_product{
      .checkout-btn {
        button.btn.btn-primary { 
          display: table;
          float: none;
          text-align: center;
          justify-content: center;
          margin: 0 auto;
          color: #fff;
          max-width: fit-content;
          width: 100%;
          border-radius: 25px;
          border: 2px solid var(--blue);
          transition: 0.3s ease all;
          background-color: var(--blue);
          padding: 8px 30px;
          font-size: 16px;
          transition: 0.3s ease all;  
          &:hover{
            background-color: #fff;
            color: var(--blue);
          }
      } 
    }
   
}

    .mini-cart { 
      .min-cat-uppper {
        .cart-image {
          img {
            mix-blend-mode: multiply;
          }
        }
        .mini-cart-des {
          .cart-product-details {
            .box {
              width: 100%;
              .select {
                display: table;
                width: 100%;
              }
            }

            :first-child {
              font-weight: 400;
              font-family: "Graphik-bold";
              color: var(--blue);
            }

            .title{
              &>div {
                display: flex;
              }
            }

            .price-beforediscount {
              text-decoration: line-through;
              color: #ff0000;
              margin-left:5px;
            }
          }
          .main-qty-sec {
            .box {
              #qty {
                max-width: 80% !important;
                display: flex;
                justify-content: center;
                border: 1px solid var(--blue);
                button {
                  background: #fff;
                  border-radius: 0 !important;
                  width: 32px;
                  font-size: 19px;
                  font-weight: 600;
                }
                input {
                  width: 40px;
                  background: #fff;
                  border-left: 1px solid var(--blue);
                  border-right: 1px solid var(--blue);
                }
                .sub {
                  left: 0;
                }
                .add {
                  right: 0;
                }
              }
            }
          }
        }
      }
      .cart-product-qty-del {
        // bottom: 32px!important;
        svg {
          font-size: 26px;
        }
        button.dlt {
          background-color: #fff;
          border-radius: 0px;
          font-size: 0px;
          font-weight: bold;
          line-height: 1;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          border: 1px solid red;
          padding: 0;
          color: red;
          border-radius: 50%;
          &:before {
          }
          svg {
            width: 20px;
            height: 20px;
            fill: red;
          }
        }
      } 
      .free_product_title {
        padding: 6px;
        background: #f00;
        width: 100%;
        /* max-width: 315px; */
        border: none;
        margin-top: 5px;
        /* @media (max-width: 767px){
          max-width: 266px;
        } */
        p {
          margin: 0;
          font-size: 20px;
          font-family: "Graphik-bold";
          color: #fff;
          text-align: center;
        }
      }
    }

    .checkout-btn {
      bottom: 0; 
      .col-md-12{
        padding: 0;
      }
      .d-flex.btn-flex {
        width: 100%;
      button.btn.btn-primary {
        width: 100%;
        border-radius: 50px;
        font-size: 14px;
        background: #06356a;
        border: 2px solid #06356a;
        height: 44px;
        &:hover {
          background: #fff;
          color: #06356a;
        }
        
      }
      }
    }
  }


`;

export default MinCartStyle;
